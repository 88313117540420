/* centered header */
.ag-header-cell-label{
  justify-content: center !important;
}
.ag-header-group-cell-label {
  justify-content: center !important;
}
/* multiline header */
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.ag-react-container {
  width: 100%
}

.ag-center-cols-clipper {
  min-height: 48px !important; /* used to be 150px */
}

.ag-center-cols-container {
  min-height: 48px !important; /* used to be 150px */
}

.ag-cell-wrap-text {
  word-break: unset;
}
