.iconContainer {
    background-color: rgb(13, 60, 90);
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .iconClass {
        margin-left: 8px;
    }

    &:hover {
        cursor: pointer;
        background-color: rgb(9, 42, 62);
    }
}
