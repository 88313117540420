.sui-layout-body__inner {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 0 24px;
}

.sui-layout-sidebar {
    width: 250px;
    padding: 40px 0 0 0;
}

.sui-layout-main {
    width: 100%;
    padding: 32px 0 32px 32px;
    overflow: auto;
}

.sui-multi-checkbox-facet__option-input-wrapper {
    display: flex !important;
    align-items: flex-start;
}

.sui-multi-checkbox-facet__checkbox {
    margin-top: 3px;
    margin-right: 8px;
    cursor: pointer;
}

.sui-facet__title {
    text-transform: uppercase;
    font-size: 12px;
    color: #172b4d;
    letter-spacing: 1px;
    padding: 0;
}

.sui-multi-checkbox-facet {
    color: #172b4d;
    font-size: 13px;
    margin: 8px 0;
}

.sui-multi-checkbox-facet__input-text {
    text-transform: lowercase;
}

.sui-multi-checkbox-facet__input-text:first-letter {
    text-transform: uppercase;
}
